import $ from 'jquery';
import prestashop from 'prestashop';

$(function(){
	var avisConfig = {
		speed: 500,            // Integer: Speed of the transition, in milliseconds
		timeout: 2000, // Integer: Time between slide transitions, in milliseconds
		nav: false,             // Boolean: Show navigation, true or false
		random: false,          // Boolean: Randomize the order of the slides, true or false
		pause: false, // Boolean: Pause on hover, true or false
		maxwidth: "",           // Integer: Max-width of the slideshow, in pixels
		namespace: "avislider",   // String: Change the default namespace used
		before: function(){},   // Function: Before callback
		after: function(){}     // Function: After callback
	};
	
	$(".temoignages").responsiveSlides(avisConfig);
	//alert("fff");
});