import $ from 'jquery';
import prestashop from 'prestashop';
// eslint-disable-next-line
import "velocity-animate";

$(document).ready(() => {
	$('.addToCartBlock .plus-btn').click(function(){
		console.log($(this).siblings('.qte-prod').first());
		var valueQty = parseInt($(this).siblings('.qte-prod').first().val());
		$(this).siblings('.qte-prod').first().val(valueQty+1);
	});

	$('.addToCartBlock .moins-btn').click(function(){
		console.log($(this).siblings('.qte-prod').first());
		var valueQty = parseInt($(this).siblings('.qte-prod').first().val());
		if(valueQty>=2){
			$(this).siblings('.qte-prod').first().val(valueQty-1);
		}
	});
});
